import React from "react"

import Layout from "../components/layout"
import AboutusPage from "../components/about/index"

import Seo from "../components/seo"

const aboutPage = () => (
  <Layout>
    <Seo title="Bradiance" desc="Bradiance" keywords="Bradiance" />
    <AboutusPage />
  </Layout>
)

export default aboutPage
